<template>
    <ValidationProvider class="block" :rules="getRules" v-slot="field" :vid="name" :name="name">
        <label :for="id" :class="labelClass + ' ' + diff_class">{{ labelText }}</label>
        <select :class="diff_class" :name="name" :id="id" :value='value'         
        :disabled="disabled" 
        @change="onSelectChange">
            <option :disabled="disableEmpty" value="">{{placeholder}}</option>
            <option
                v-for="(option, index) in options"
                :key="index"
                :value="getOptionValue(option)"
                :disabled="option.disabled"
            >
                {{ getOptionText(option) }}
            </option>
        </select>
        <span :class="errorClass">{{ field.errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'CounselorDropDown',
        components: { ValidationProvider },
        props: {
            name: String,
            id: String,
            value: [String, Number],
            label: String,
            labelClass: String,
            errorClass: String,
            options: {
                type: Array,
                required: true,
            },
            required: Boolean,
            disableEmpty: Boolean,
            disabled: Boolean,
            placeholder: String,
            portal_data: {
                type: Object,
                required: false
            },
            field_name: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                rules: '',
            };
        },
        methods: {
            onSelectChange(event){
                if(event.target.value!=this.value)
                this.$emit('change', event.target.value)
            },
            getOptionValue(option) {
                return option?.value ?? option;
            },
            getOptionText(option) {
                return option?.text ?? this.ucwords(option);
            },
            ucwords(str) {
                str = str.toString().replaceAll('_', ' ');
                return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                    return $1.toUpperCase();
                });
            },
        },
        computed: {
            labelText() {
                return this.label + (this.required ? '*' : '');
            },
            getRules() {
                const rules = this.rules || '';

                if (this.required) {
                    if (!rules.includes('required')) {
                        return rules.length > 0 ? rules + '|required' : 'required';
                    }
                }

                return rules;
            },
            diff_class() {
                let txt_class = 'dummy-text-class'
                if (this.portal_data && this.portal_data.pokeme && this.portal_data.data && this.field_name && this.portal_data.data[this.field_name] && this.portal_data.original_data && this.portal_data.data[this.field_name] != this.portal_data.original_data[this.field_name]) {
                    txt_class = 'dark-red bold'
                }
                return txt_class
            },
        },
    };
</script>
